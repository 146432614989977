import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GuestHeader = _resolveComponent("GuestHeader")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    class: _normalizeClass(["ion-page", { 'safe-area': _ctx.safeArea }])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header", {}, () => [
        _createVNode(_component_GuestHeader, {
          hideMenuButton: _ctx.hideMenuButton,
          showBackButton: _ctx.showBackButton,
          customBackButtonFunc: _ctx.customBackButtonFunc,
          hideLogo: _ctx.hideLogo,
          useCustomFunc: _ctx.useCustomFunc
        }, null, 8, ["hideMenuButton", "showBackButton", "customBackButtonFunc", "hideLogo", "useCustomFunc"])
      ]),
      _createVNode(_component_ion_content, {
        scrollY: _ctx.scrollY,
        ref: "ionContent"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["scrollY"])
    ]),
    _: 3
  }, 8, ["class"]))
}