import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"min-height":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_TheoryQuestions = _resolveComponent("TheoryQuestions")!
  const _component_GuestLayout = _resolveComponent("GuestLayout")!

  return (_openBlock(), _createBlock(_component_GuestLayout, {
    "show-back-button": true,
    "safe-area": true
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BackButton, {
          class: "landscepe-hidden my-2 container-fluid",
          onClick: _ctx.goBack,
          cols: "12"
        }, null, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_TheoryQuestions, {
        loading: _ctx.randomTheoryQuestionLoading,
        question: _ctx.randomTheoryQuestion,
        onNext: _ctx.goToNext,
        "go-back": _ctx.goBack
      }, null, 8, ["loading", "question", "onNext", "go-back"])
    ]),
    _: 1
  }))
}