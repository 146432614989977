
import { Options, Prop, Provide, Vue } from "vue-property-decorator";
import { IonPage, IonContent } from "@ionic/vue";
import GuestHeader from "@/components/Headers/GuestHeader.vue";

@Options({
  components: {
    GuestHeader,
    IonPage,
    IonContent,
  },
})
export default class GuestLayout extends Vue {
  public name = "GuestLayout";

  private scrollY = true;

  @Prop({ type: Boolean, default: () => false })
  public hideMenuButton!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public showBackButton!: boolean;

  @Prop({ type: Function, default: () => null })
  public customBackButtonFunc!: () => void;

  @Prop({ type: Boolean, default: () => false })
  public hideLogo!: any;

  @Prop({ type: Boolean, default: () => false })
  public useCustomFunc!: any;

  @Prop({ type: Boolean, default: () => false })
  public safeArea!: boolean;

  @Provide({ to: "setScrollY" })
  public setScrollY(value: boolean) {
    console.log(value, "setScrollY");
    this.scrollY = value;
  }

  @Provide({ to: "scrollToPoint" })
  public scrollToPoint(valueY: number) {
    console.log(valueY, "scrollToPoint");
    this.$nextTick(() => {
      //@ts-ignore
      this.$refs.ionContent.$el.scrollToPoint(null, valueY);
    });
  }
}
